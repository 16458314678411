//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from "bootstrap-vue";
import { Icon } from "@iconify/vue2";

export default {
  name: "CourseFloatingCard",
  components: { BCard, BCardBody, Icon },
  props: {
    img: String,
    imgAlt: String,
    name: String,
    shortName: String,
    priceUSD: Number,
    pricePesos: Number,
    dateCourse: String,
    language: String,
    level: String,
    duration: String,
    slug: String,
    shortDescription: String,
    graduationCertificate: Boolean,
  },
  methods: {
    saveCourseData() {
      const courseData = {
        name: this.name,
        shortName: this.shortName,
        priceUSD: this.priceUSD,
        pricePesos: parseFloat(this.pricePesos),
        img: this.img,
        language: this.language,
        level: this.level,
        duration: this.duration,
        dateCourse: this.dateCourse,
        slug: this.slug,
        shortDescription: this.shortDescription,
        graduationCertificate: this.graduationCertificate,
      };

      Object.entries(courseData).forEach(([key, value]) => {
        try {
          if (key === "pricePesos" || key === "priceUSD") {
            const numValue = parseFloat(value);
            sessionStorage.setItem(
              `course_${key}`,
              isNaN(numValue) ? "0" : numValue.toString()
            );
          } else {
            sessionStorage.setItem(`course_${key}`, JSON.stringify(value));
          }
        } catch (e) {
          console.error(`Error guardando ${key}:`, e);
          sessionStorage.setItem(`course_${key}`, value);
        }
      });

      const savedName = sessionStorage.getItem("course_name");
      const savedPrice = sessionStorage.getItem("course_pricePesos");
      return savedName && savedPrice;
    },
    handleStartCourse() {
      // verifica si ya hay un token
      const token = sessionStorage.getItem("login_key");
      if (token) {
        // si ya está autenticado, se guardan los datos y se va directo a la página de pago
        if (this.saveCourseData()) {
          this.$router.push(this.localePath("/pago/" + this.slug));
        }
      } else {
        // si no está autenticado, se guardan los datos y se muestra el modal de login
        this.saveCourseData();
        if (this.$root.$loginSheet) {
          this.$root.$loginSheet.$once("auth-success", this.handleAuthSuccess);
          this.$modal.show("login-sheet");
        } else {
          console.error("LoginSheet no está disponible");
        }
      }
    },
    async handleAuthSuccess() {
      // se asegura de que los datos estén guardados antes de navegar
      if (this.saveCourseData()) {
        // cuando el login es exitoso y los datos están guardados, se va directo a la página de pago
        this.$router.push(this.localePath("/pago/" + this.slug));
      } else {
        console.error("Error al guardar los datos del curso");
      }
    },
  },
};
