//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BNavItemDropdown, BDropdownItem } from "bootstrap-vue";

export default {
  name: "UserAvatar",
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  data() {
    return {
      userData: null,
      isAuthenticated: false,
    };
  },
  computed: {
    avatarUrl() {
      if (!this.userData?.firstName || !this.userData?.lastName) return "";
      const firstName = this.userData.firstName || "";
      const lastName = this.userData.lastName || "";
      if (!firstName.length || !lastName.length) return "";
      return `https://ui-avatars.com/api/?name=${encodeURIComponent(
        firstName[0] + lastName[0]
      )}&background=6366F1&color=fff&size=32`;
    },
  },
  mounted() {
    if (process.client) {
      this.checkAuth();
      this.$root.$on("user-authenticated", this.checkAuth);
    }
  },
  beforeDestroy() {
    if (process.client) {
      this.$root.$off("user-authenticated", this.checkAuth);
    }
  },
  methods: {
    checkAuth() {
      if (!process.client) return;
      const userDataStr = sessionStorage.getItem("user_data");
      const loginKey = sessionStorage.getItem("login_key");
      this.isAuthenticated = !!(userDataStr && loginKey);
      if (this.isAuthenticated) {
        this.loadUserData();
      } else {
        this.userData = null;
      }
    },
    loadUserData() {
      if (!process.client) return;
      const userDataStr = sessionStorage.getItem("user_data");
      if (!userDataStr) {
        this.cleanUserData();
        return;
      }

      try {
        const data = JSON.parse(userDataStr);
        const user = data?.user;
        if (!user) {
          console.error("No user data found:", data);
          this.cleanUserData();
          return;
        }

        const firstName = user.first_name || user.firstName;
        const lastName = user.last_name || user.lastName;

        if (!firstName || !lastName) {
          console.error("Missing name data:", user);
          this.cleanUserData();
          return;
        }

        this.userData = {
          firstName,
          lastName,
        };
      } catch (e) {
        console.error("Error parsing user data:", e);
        this.cleanUserData();
      }
    },
    cleanUserData() {
      sessionStorage.removeItem("login_key");
      sessionStorage.removeItem("user_data");
      sessionStorage.removeItem("login_key_used");
      this.isAuthenticated = false;
      this.userData = null;
    },
    handleLogout() {
      this.cleanUserData();
      this.$router.push("/es");
    },
    handleMisCursos() {
      const loginKey = sessionStorage.getItem("login_key");
      const loginKeyUsed = sessionStorage.getItem("login_key_used");

      if (loginKey && !loginKeyUsed) {
        sessionStorage.setItem("login_key_used", "true");
        window.open(loginKey, "_blank");
      } else {
        window.open("https://web.academy.abstracta.us/dashboard", "_blank");
      }
    },
  },
};
