//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardText } from "bootstrap-vue";
import { Icon } from "@iconify/vue2";
import { getCourseDurationText } from "~/utils/course";

export default {
  name: "CourseCard",
  components: { BCard, BCardText, Icon },
  props: {
    image: String,
    imgAlt: String,
    language: String,
    duration: Object,
    name: String,
    shortName: String,
    shortDescription: String,
    category: String,
    url: [String, Object],
    level: String,
  },
  methods: {
    getCourseDurationText,
  },
};
