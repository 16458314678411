//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from "@iconify/vue2";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import VueRecaptcha from "vue-recaptcha";
import { waitForCondition } from "~/utils";

extend("password", {
  validate: (value) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasMinLength = value.length >= 8;

    return hasUpperCase && hasLowerCase && hasNumber && hasMinLength;
  },
  message:
    "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número",
});

export default {
  name: "LoginSheet",
  components: {
    Icon,
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
  },
  data() {
    return {
      isLogin: true,
      loading: false,
      error: null,
      showPassword: false,
      captchaResponse: null,
      captchaState: null,
      formData: {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
      },
    };
  },
  computed: {
    passwordRules() {
      if (this.isLogin) {
        return { required: true };
      }
      return {
        required: true,
        password: true,
      };
    },
    alertType() {
      return {
        "alert-danger": this.error,
      };
    },
    alertIcon() {
      return "mdi:alert-circle";
    },
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      this.loading = true;
      this.error = null;

      try {
        // run recaptcha
        this.$refs.recaptcha.execute();
        await waitForCondition(() => {
          return this.captchaResponse || this.captchaState === false;
        }, -1);

        if (!this.captchaResponse) {
          this.error = "Por favor, verifica que no eres un robot";
          return;
        }

        if (this.isLogin) {
          const response = await this.$api.auth.login({
            email: this.formData.email,
            password: this.formData.password,
            captcharesponse: this.captchaResponse,
          });

          if (response.error) {
            switch (response.error) {
              case "User not found":
                this.error = "Usuario no encontrado, por favor crea una cuenta";
                break;
              case "Invalid credentials":
                this.error =
                  "Contraseña incorrecta, por favor intenta nuevamente";
                break;
              case "Email and password are required":
                this.error = "Por favor, completa todos los campos";
                break;
              default:
                this.error = "Error al procesar la solicitud";
            }
            return;
          }

          sessionStorage.setItem("login_key", response.login_key);
          sessionStorage.setItem("user_data", JSON.stringify(response));
          this.$modal.hide("login-sheet");
          this.$emit("auth-success");
          this.$root.$emit("user-authenticated");
        } else {
          const userExists = await this.$api.auth.checkUserExists(
            this.formData.email
          );

          if (userExists) {
            this.error = "Este email ya está registrado";
            return;
          }

          const response = await this.$api.auth.signup(this.formData);

          if (response.error) {
            switch (response.error) {
              case "User already exists":
                this.error =
                  "Este email ya está registrado, por favor inicia sesión";
                break;
              case "Email and password are required":
                this.error = "Por favor, completa todos los campos";
                break;
              default:
                this.error = "Error al procesar la solicitud";
            }
            return;
          }

          sessionStorage.setItem("login_key", response.login_key);
          sessionStorage.setItem("user_data", JSON.stringify(response));
          this.$modal.hide("login-sheet");
          this.$emit("auth-success");
          this.$root.$emit("user-authenticated");
        }
      } catch (error) {
        console.error("Auth error:", error.response || error);
        const errorMessage = error.response?.data?.error || error.message;

        switch (errorMessage) {
          case "Invalid credentials":
            this.error =
              "Correo o contraseña incorrecta, por favor intenta nuevamente";
            break;
          case "User not found":
            this.error = "Usuario no encontrado, por favor crea una cuenta";
            break;
          case "Email and password are required":
            this.error = "Por favor, completa todos los campos";
            break;
          case "User already exists":
            this.error =
              "Este email ya está registrado, por favor inicia sesión";
            break;
          default:
            this.error = "Error al procesar la solicitud";
        }
      } finally {
        this.loading = false;
      }
    },
    onCaptchaVerify(response) {
      this.captchaState = true;
      this.captchaResponse = response;
    },
    onCaptchaError() {
      this.captchaState = false;
      this.captchaResponse = null;
      this.error = "Error al verificar el captcha";
    },
    onCaptchaExpired() {
      this.captchaResponse = null;
    },
    toggleMode() {
      this.isLogin = !this.isLogin;
      this.error = null;
      this.formData = {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
      };
      this.$refs.observer.reset();
    },
  },
};
