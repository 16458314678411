import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5a15e008"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=5a15e008&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/builds/marketing/abstracta.academy-website/components/NavBar.vue').default,LoginSheet: require('/builds/marketing/abstracta.academy-website/components/LoginSheet.vue').default})
