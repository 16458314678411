//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from "@iconify/vue2";

export default {
  name: "PaymentNavigation",
  components: { Icon },
  props: {
    courseName: {
      type: String,
      required: true,
    },
  },
};
