//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

let modalTimeout;
import LoginSheet from "~/components/LoginSheet.vue";

export default {
  components: { LoginSheet },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      meta: [...i18nHead.meta],
      link: [...i18nHead.link],
    };
  },
  mounted() {
    // Registrar el LoginSheet en el bus de eventos global
    this.$root.$loginSheet = this.$refs.loginSheet;

    /*modalTimeout = setTimeout(() => {
      const showModal = window.sessionStorage.getItem("showPromoModal");
      if (!showModal) {
        this.$refs.promoModal.showModal();
        window.sessionStorage.setItem("showPromoModal", true);
      }
    }, 2500);*/
  },
  watch: {
    $route(to, from) {
      // Reset form on navigation
      this.$refs.newsletterForm.$children[0].resetForm();
    },
  },
};
