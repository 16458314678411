//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from "bootstrap-vue";
import { Icon } from "@iconify/vue2";

export default {
  name: "CourseCardSummary",
  components: { BCard, BCardBody, Icon },
  props: {
    description: Array,
    topics: Array,
  },
  methods: {
    onBtnClick() {
      // se emite un evento para que el padre (curso.vue) maneje el click
      this.$emit("start-course");
    },
  },
};
