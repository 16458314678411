//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BBreadcrumb, BCard, BCardBody } from "bootstrap-vue";
import { Icon } from "@iconify/vue2";
import { getCourseDurationText } from "~/utils/course";
import { loadCourse } from "~/data/courses/index";

export default {
  name: "Curso",
  layout: "default",
  components: { BBreadcrumb, BCard, BCardBody, Icon },
  async asyncData(context) {
    const cursoId = context.params.curso;
    try {
      const courseInfo = await loadCourse(cursoId);
      if (!courseInfo) {
        throw new Error("Course not found");
      }
      return { courseInfo };
    } catch (e) {
      const path = context.localePath("404");
      context.redirect(path);
    }
  },
  head() {
    if (!this.courseInfo) return {};
    const { title, description, keywords, imageUrl } =
      this.courseInfo.metaTags || {};
    return {
      title: title || "Curso",
      meta: [
        {
          hid: "description",
          name: "description",
          content: description || "Información del curso",
        },
        { hid: "keywords", name: "keywords", content: keywords || "" },
        { hid: "og:image", property: "og:image", content: imageUrl || "" },
      ],
    };
  },
  data() {
    return {
      floatingCardBottomLimit: Number.MAX_VALUE,
      pricePesos: 0,
      category: "",
      paymentMethod: ["paypal"],
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: "Cursos",
          to: this.localePath("/cursos"),
        },
        {
          text: this.courseInfo?.category || "",
          to: {
            path: this.localePath(
              `/cursos?category=${this.courseInfo?.category}`
            ),
            hash: "#cursos",
          },
        },
        {
          text: this.courseInfo?.name || "",
          active: true,
        },
      ];
    },
  },
  methods: {
    calculateFloatingCardLimit() {
      if (this.$refs["card-specialization"]) {
        const { $el: el } = this.$refs["card-specialization"];
        const { top, height } = el.getBoundingClientRect();
        this.floatingCardBottomLimit =
          document.documentElement.scrollTop + top + height;
      } else if (this.$refs["card-bioinstructors"]) {
        const { $el: el } = this.$refs["card-bioinstructors"];
        const { top, height } = el.getBoundingClientRect();
        this.floatingCardBottomLimit =
          document.documentElement.scrollTop + top + height;
      } else if (this.$refs["card-modules"]) {
        const { $el: el } = this.$refs["card-modules"];
        const { top, height } = el.getBoundingClientRect();
        this.floatingCardBottomLimit =
          document.documentElement.scrollTop + top + height;
      }
    },

    getCourseDurationText,
    onBtnClick() {
      // usamos el LoginSheet global
      const token = sessionStorage.getItem("login_key");
      if (token) {
        // si ya está autenticado, se guardan los datos y se va directo a la página de pago
        const courseData = {
          name: this.courseInfo.name,
          shortName: this.courseInfo.shortName,
          priceUSD: this.courseInfo.priceUSD,
          pricePesos: this.pricePesos,
          img: this.courseInfo.image,
          language: this.courseInfo.language,
          level: this.courseInfo.level,
          duration: this.courseInfo.duration,
          dateCourse: this.courseInfo.dateCourse,
          slug: this.courseInfo.slug,
          shortDescription: this.courseInfo.shortDescription,
          graduationCertificate: this.courseInfo.graduationCertificate,
        };

        Object.entries(courseData).forEach(([key, value]) => {
          try {
            if (key === "pricePesos" || key === "priceUSD") {
              const numValue = parseFloat(value);
              sessionStorage.setItem(
                `course_${key}`,
                isNaN(numValue) ? "0" : numValue.toString()
              );
            } else {
              sessionStorage.setItem(`course_${key}`, JSON.stringify(value));
            }
          } catch (e) {
            console.error(`Error guardando ${key}:`, e);
            sessionStorage.setItem(`course_${key}`, value);
          }
        });

        this.$router.push(this.localePath("/pago/" + this.courseInfo.slug));
      } else {
        // si no está autenticado, se guardan los datos y se muestra el modal de login
        if (this.$root.$loginSheet) {
          this.$root.$loginSheet.$once("auth-success", () => {
            this.$router.push(this.localePath("/pago/" + this.courseInfo.slug));
          });
          this.$modal.show("login-sheet");
        } else {
          console.error("LoginSheet no está disponible");
        }
      }
    },
  },
  async mounted() {
    if (!this.courseInfo) return;

    this.category = this.courseInfo.category;
    //
    this.$nextTick(() => {
      window.addEventListener("resize", this.calculateFloatingCardLimit);
      this.calculateFloatingCardLimit();
    });

    let dolarPrice = window.sessionStorage.getItem("dolarPrice");
    if (dolarPrice) dolarPrice = parseFloat(dolarPrice);
    else {
      const res = await this.$api.cotizacion.getCotizacion({ monedas: [2225] });
      dolarPrice = res[0].TCV;
      window.sessionStorage.setItem("dolarPrice", dolarPrice);
    }

    // this.pricePesos = parseFloat((this.courseInfo.priceUSD * dolarPrice) * 1.22).toFixed(2); //
    this.pricePesos = Math.round(this.courseInfo.priceUSD * dolarPrice * 1.22);
    // this.pricePesos = this.courseInfo.pricePesos;
    sessionStorage.setItem("courseName", this.courseInfo.name);
    sessionStorage.setItem("courseBadgeImg", this.courseInfo.badgeImg);
    sessionStorage.setItem("coursePriceUSD", this.courseInfo.priceUSD);
    sessionStorage.setItem("coursePricePesos", this.pricePesos);
    sessionStorage.setItem("courseTalentLink", this.courseInfo.talentLink);

    this.paymentMethod = sessionStorage.coursePaymentMethod;
  },
  watch: {},
};
