//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  BNavbar,
  BNavbarNav,
  BNavItem,
  BCollapse,
  BNavbarBrand,
  BNavbarToggle,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { Icon } from "@iconify/vue2";
import UserAvatar from "~/components/UserAvatar.vue";

export default {
  name: "NavBar",
  components: {
    BNavbar,
    BNavbarNav,
    BNavItem,
    BCollapse,
    BNavbarBrand,
    BNavbarToggle,
    Icon,
    BNavItemDropdown,
    BDropdownItem,
    UserAvatar,
  },
  data() {
    return {
      navStatus: "collapsed",
      navOpened: false,
    };
  },
  computed: {
    navCollapsed() {
      return this.navStatus == "collapsed";
    },
    navCollapsing() {
      return this.navStatus == "collapsing";
    },
  },
  methods: {
    closeNav() {
      this.navOpened = false;
    },
  },
};
