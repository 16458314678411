//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from "@iconify/vue2";

export default {
  name: "PaymentOptions",
  components: { Icon },
  props: {
    selectedPayment: {
      type: String,
      default: "",
    },
    showStatusScreen: {
      type: Boolean,
      default: false,
    },
  },
};
