//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SuccessModal",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    redirectToCourse() {
      const loginKey = sessionStorage.getItem("login_key");
      const loginKeyUsed = sessionStorage.getItem("login_key_used");

      if (loginKey && !loginKeyUsed) {
        sessionStorage.setItem("login_key_used", "true");
        window.open(loginKey, "_blank");
      } else {
        window.open("https://web.academy.abstracta.us/dashboard", "_blank");
      }
    },
    handleHide() {
      this.$emit("input", false);
    },
  },
};
