//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from "@iconify/vue2";
import CourseCard from "~/components/CourseCard.vue";
import PaymentNavigation from "~/components/payment/PaymentNavigation.vue";
import PaymentOptions from "~/components/payment/PaymentOptions.vue";
import SuccessModal from "~/components/payment/SuccessModal.vue";
import mercadoPagoMixin from "~/mixins/mercadoPago";
import { BSpinner } from "bootstrap-vue";

export default {
  name: "PaymentPage",
  components: {
    Icon,
    CourseCard,
    PaymentNavigation,
    PaymentOptions,
    SuccessModal,
    BSpinner,
  },
  mixins: [mercadoPagoMixin],
  middleware({ app }) {
    // solo corre en el cliente
    if (process.client) {
      const token = sessionStorage.getItem("login_key");
      const courseData = sessionStorage.getItem("course_name");

      if (!token || !courseData) {
        return app.$router.push(app.localePath("/cursos"));
      }
    }
  },
  data() {
    return {
      courseData: null,
      selectedPayment: "",
      showStatusScreen: false,
      showSuccessModal: false,
    };
  },
  created() {
    if (process.client) {
      this.loadCourseData();
    }
  },
  head() {
    return {
      script: [
        {
          src: "https://sdk.mercadopago.com/js/v2",
          async: true,
        },
      ],
      link: [
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap",
        },
      ],
    };
  },
  async mounted() {
    if (!process.client || !this.courseData) return;

    let attempts = 0;
    const maxAttempts = 20;

    while (!window.MercadoPago && attempts < maxAttempts) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      attempts++;
    }

    if (!window.MercadoPago) {
      console.error("No se pudo cargar el SDK de MercadoPago");
      this.$bvToast.toast(
        "No se pudo cargar el sistema de pagos. Por favor, recarga la página.",
        {
          title: "Error",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        }
      );
      return;
    }

    await this.initMercadoPago();
  },
  methods: {
    loadCourseData() {
      const data = {};
      const prefix = "course_";

      try {
        const courseKeys = Object.keys(sessionStorage).filter((key) =>
          key.startsWith(prefix)
        );

        courseKeys.forEach((key) => {
          const propertyName = key.replace(prefix, "");
          try {
            const value = sessionStorage.getItem(key);
            data[propertyName] =
              value && value !== "undefined" ? JSON.parse(value) : null;
          } catch (e) {
            data[propertyName] = sessionStorage.getItem(key);
          }
        });

        let validPrice = null;
        if (data.pricePesos !== undefined && data.pricePesos !== null) {
          const price =
            typeof data.pricePesos === "string"
              ? parseFloat(data.pricePesos.replace(/[^\d.-]/g, ""))
              : parseFloat(data.pricePesos);

          if (!isNaN(price) && price > 0) {
            validPrice = price;
          }
        }

        if (validPrice === null) {
          throw new Error("Precio inválido");
        }

        data.pricePesos = validPrice;
        data.duration =
          typeof data.duration === "string"
            ? { hours: parseInt(data.duration) || 0 }
            : data.duration;
        data.category = data.category || "";
        data.description = data.description || data.shortDescription || "";
        data.courseTalentLink = sessionStorage.getItem("courseTalentLink");

        this.courseData = data;
      } catch (error) {
        console.error("Error al cargar datos del curso:", error);
        this.$router.push(this.localePath("/cursos"));
      }
    },
    handlePayment(method) {
      if (this.selectedPayment === method) {
        if (method === "mercadopago") {
          this.resetMercadoPagoState();
        }
        this.selectedPayment = "";
        return;
      }

      if (this.selectedPayment === "mercadopago") {
        this.resetMercadoPagoState();
      }

      this.selectedPayment = method;
      if (method === "mercadopago") {
        this.processPurchase();
      } else if (method === "paypal" && this.courseData?.courseTalentLink) {
        window.location.href = this.courseData.courseTalentLink;
      }
    },
    async processPurchase() {
      try {
        const price = parseFloat(this.courseData?.pricePesos);
        if (
          this.courseData?.pricePesos === undefined ||
          this.courseData?.pricePesos === null ||
          isNaN(price) ||
          price <= 0
        ) {
          console.error("Precio inválido:", {
            original: this.courseData?.pricePesos,
            parsed: price,
          });
          this.$bvToast.toast(
            "Error al cargar el precio del curso. Por favor, vuelve a la página del curso e intenta nuevamente.",
            {
              title: "Error",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center",
              appendToast: false,
            }
          );
          return;
        }

        this.$nuxt.$loading.start();
        await this.renderPaymentBrick();
      } catch (error) {
        console.error("Error en el proceso de pago:", error);

        let errorMessage = "Error al procesar el pago. ";
        if (error.response?.data?.message) {
          errorMessage += error.response.data.message;
        } else if (error.message) {
          errorMessage += error.message;
        } else {
          errorMessage += "Por favor intenta nuevamente o contacta a soporte.";
        }

        this.$bvToast.toast(errorMessage, {
          title: "Error",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
          appendToast: false,
        });
      } finally {
        this.$nuxt.$loading.finish();
      }
    },
  },
};
