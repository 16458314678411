import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=19937047&scoped=true"
import script from "./_slug.vue?vue&type=script&lang=js"
export * from "./_slug.vue?vue&type=script&lang=js"
import style0 from "./_slug.vue?vue&type=style&index=0&id=19937047&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19937047",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PaymentNavigation: require('/builds/marketing/abstracta.academy-website/components/payment/PaymentNavigation.vue').default,PaymentOptions: require('/builds/marketing/abstracta.academy-website/components/payment/PaymentOptions.vue').default,CourseCard: require('/builds/marketing/abstracta.academy-website/components/CourseCard.vue').default})
